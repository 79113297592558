<template lang="pug">
TheBook(
	:title="organizations.title"
	:endpoint="organizations.endpoint"
	:path="organizations.path"
	:left="organizations.left"
	:leftSub="organizations.leftSub"
)
</template>

<script>
import TheBook from '@/components/TheBook'
import BOOKS from '@/modules/constants/books'

export default {
	name: 'Earners',
	components: { TheBook },
	setup () {
		const { organizations } = BOOKS

		return {
			organizations
		}
	}
}
</script>
